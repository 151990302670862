import './App.css';
import Formm from './Form/form';

function App() {
  return (
    <div className="App">
      <Formm />
    </div>
  );
};

export default App;
